<template>

  <div class="wrapper">

    <div class="body" style="padding:0;">

      <Slider></Slider>

      <div class="">

        <section class="container" style="height: auto">

          <!-- Title and Link -->
          <div class="row mt-2 mb-2">
            <div class="col-12 d-flex justify-content-between align-items-center">
              <h5>🔥 New Games 🔥</h5>
            </div>
          </div>

          <!-- First Image (Full Width) -->
          <div class="row justify-content-around">

            <div v-for="(o, x) in allgames" :key="getKey(x)" class="col-sm-6 col-4 col-md-4 crash-game fly-game">

              <router-link :to="{ name: 'casinogames', params: { provider:'stp', IsDemo: 0, GameID: o.game_id, gameUrl: o.url, GameName: o.title } }">

                <img v-bind:src="o.image_squarex3_thumbnail" class="img-fluid rounded border rocket-fastball" v-bind:alt="o.title" style="width: 100%;">

              </router-link>

            </div>

          </div>

        </section>

      </div>

    </div>

  </div>

</template>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 40%; */
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 5px;
}

.crash-game {
  padding: .2em;
}

.fly-game{
  text-align: center;
}

/* .modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
} */
</style>

<script>

import Vue from 'vue'
import api from "@/services/api";
//import ShimmerCasino from "../shimmer/ShimmerCasino.vue";
import ScrollLoader from 'vue-scroll-loader'

const Slider = () => import('../SliderCasino.vue');

Vue.use(ScrollLoader)
export default {
  name: "Stp",
  components: {
    Slider,
   // ShimmerCasino
  },
  data: function () {
    return {
      allgames: [],
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "stp");

    this.getCasinoGames();

    this.reloadProfile();

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    handleGesture: function () {
      var scrollLeft = document.getElementById('catsec').scrollLeft
      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();

      var tokn = profile.t1;

      if(tokn === undefined) {

        this.$router.push({ name: 'login', params: { } });
        return ;

      }

      let accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      tokn = accountId + '' + tokn

      launch_url = launch_url.replaceAll("{token}", tokn);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function () {

      var path = process.env.VUE_APP_STP_GET_GAME_URL;

      var vm = this;
      vm.allgames = [];

      api.post(path,{})
          .then((resp) => {


            vm.allgames = resp.data.games;

            vm.jQuery.each(vm.allgames, function (i, x) {

             // x.url = vm.getLaunchURL(x.url)
              vm.allgames[i] = x;

            });

            this.loading = false;

          });
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>